<template scoped>
  <div class="designtool-arrow">
    <div class="designtool-arrow-line"></div>
    <div class="designtool-arrow-bottom"></div>
  </div>
</template>

<script>
export default {};
</script>

<style scoped>
.designtool\-arrow{
  width: 300px;
}

.designtool\-arrow\-line {
  height: 50px;
  background: #000;
  margin: 0px auto;
  margin-top: 10px;
  width: 2px;
}

.designtool\-arrow\-bottom {
  width: 0;
  height: 0;
  margin: 0px auto;
  margin-bottom: 10px;
  border-left: 20px solid transparent;
  border-right: 20px solid transparent;
  border-top: 20px solid #000;
}
</style>